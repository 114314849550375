import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_PATH, Configuration } from '@adlatus-gui/domain/clearing';
import {
  ClearingTicketAssignTicketCreate,
  ClearingTicketBulkAssignTicketCreate,
  UserRepresentation
} from '@adlatus-gui/domain/clearing-custom';
import { ClearingTicketAssignmentResponse } from '../model/clearingTicketAssignmentResponse';

@Injectable()
export class ClearingTicketAssignmentService {
  protected baseConfigPath = '';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) baseConfigPath: string, @Optional() configuration: Configuration) {
    if (baseConfigPath) {
      this.baseConfigPath = baseConfigPath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.baseConfigPath = baseConfigPath || configuration.basePath || this.baseConfigPath;
    }
  }


  public listPossibleAssignees(scenarioId: string) {
    return this.httpClient.request<Array<UserRepresentation>>('get', `${this.baseConfigPath}/troubleTicket/assignees/${scenarioId}`);
  }

  public listPossibleBulkAssignees(scenarioIds: string[]) {
    return this.httpClient.request<Array<UserRepresentation>>('post', `${this.baseConfigPath}/troubleTicket/assignees`, {
      body: { scenarios: scenarioIds }
    });
  }

  public assignTicket(assignTicketCreate: ClearingTicketAssignTicketCreate) {
    return this.httpClient.request<ClearingTicketAssignmentResponse>('post', `${this.baseConfigPath}/troubleTicket/assign`, {
      body: assignTicketCreate
    });
  }


  public createBulkAssign(assignBulkcreate: ClearingTicketBulkAssignTicketCreate) {
    return this.httpClient.request<ClearingTicketAssignmentResponse[]>('post', `${this.baseConfigPath}/troubleTicket/bulk-assign`, {
      body: assignBulkcreate
    });
  }

  public retrieveTicketAssignee(ticketId: number) {
    return this.httpClient.request<UserRepresentation>('get', `${this.baseConfigPath}/troubleTicket/${ticketId}/assignee`);
  }
}
